import React, { useState } from "react";
import Stars from "../reviews/Logout/Stars";



function Faq() {

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        {
            name: "Keith C.",
            date: "March 11, 2021",
            // tittle: "Movers on the way is the best!",
            description: "Carlos and his crew was super easy to work with. He is kind and helpful. Having run my own woodworking business, I can vouch that they used solid materials that will last a very long time and worked efficiently. There was some trash left on my property that I had to pick up so my dogs wouldn’t eat wrappers and food, but just a few items. I will definitely use him when the time comes to install an electric gate and to expand the fence to the entire property. Can’t recommend them enough!",
            rate: 5,
            red: "Angi",
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
        },
        {
            name: "Anne C.",
            date: "Nov 11, 2021",
            // tittle: "Movers on the way is the best!",
            description: "Contacted Quality #1 Fence and Deck for a quote for a new deck. They were able to come quickly and came on time. After reviewing the quote we decided to move forward. Communication with the office was easy and we were able to get scheduled quickly. The crew showed up on time and built out a very nice looking deck. We had porch that we wanted the deck level with and they made it perfect! We would hire again.",
            rate: 5,
            red: "Angi",
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',

        },
        {
            name: "Matt Y.",
            date: "Jun 01, 2021",
            // tittle: "Movers on the way is the best!",
            description: "Replaced and removed collapsing wood privacy fence, installed new chain link with double gate",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },
        {
            name: "Beth B.",
            date: "December 01, 2020",
            // tittle: "Movers on the way is the best!",
            description: "These folks were so nice and work was speedy despite wood shortage. I’m very happy with the work and responsiveness at a fair price. Many thanks!",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },
        {
            name: "Jerry R.",
            date: "August 29, 2020",
            // tittle: "Movers on the way is the best!",
            description: "Decent price, but I chose a company not listed by Angie's List",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },
        {
            name: "Jo H.",
            date: "August 02, 2020",
            // tittle: "Movers on the way is the best!",
            description: "Great. Glad decided to replace the entire deck.",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },

        {
            name: "Mark H.",
            date: "Jun 28, 2020",
            // tittle: "Movers on the way is the best!",
            description: "This crew is amazing and efficient! The entire project was completed in less than 2 days, We needed and 81 ft privacy fence and double gate doors replaced. We also needed one solid long 9 ft door built. They came and quoted the cost and were here the very next day. I highly recommend them! Cost and quality was spot on!",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },

        {
            name: "Patrick S.",
            date: "Jun 28, 2020",
            // tittle: "Movers on the way is the best!",
            description: "This crew is amazing and efficient! The entire project was completed in less than 2 days, We needed and 81 ft privacy fence and double gate doors replaced. We also needed one solid long 9 ft door built. They came and quoted the cost and were here the very next day. I highly recommend them! Cost and quality was spot on!",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },
        {
            name: "Lisa M.",
            date: "May 07, 2020",
            // tittle: "Movers on the way is the best!",
            description: "Excellent! Very Professional, easy to work with, no hidden costs. Prompt, friendly good pricing. Got the job done in 1 day! Love it!",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },

        {
            name: "Randy S.",
            date: "August 02, 2020",
            // tittle: "Movers on the way is the best!",
            description: "They did a great job. The fence and gates look very nice. We will definitely hire them for any future fence or deck work.",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },

        {
            name: "Keith R.",
            date: "April 08, 2020",
            // tittle: "Movers on the way is the best!",
            description: "Absolutely amazing! The crew that showed up to do the work was on time and very professional! Kept me updated throughout the entire process. Thank you!",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },

        {
            name: "Keith R.",
            date: "April 08, 2020",
            // tittle: "Movers on the way is the best!",
            description: "Absolutely amazing! The crew that showed up to do the work was on time and very professional! Kept me updated throughout the entire process. Thank you!",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },

        {
            name: "Harold H.",
            date: "Nov 05, 2020",
            // tittle: "Movers on the way is the best!",
            description: "So, I had hired a contractor that began building the fence but it became obvious that he didn't know what he was doing. It was bad enough that codes had received a complaint and needed the issues resolved within the next 4 days (this was on a Thursday, deadline was the upcoming Tuesday). So, I fired the first contractor and called Quality fence and deck co. Not only did they show up on a Sunday to begin working,",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },

        {
            name: "Mackenzie H.",
            date: "August 03, 2020",
            // tittle: "Movers on the way is the best!",
            description: "The crew was wonderful...they arrived as scheduled and worked long hours until the job was completed. We are very satisfied with the quality and workmanship.",
            rate: 5,
            enlace: 'https://www.angi.com/companylist/us/tn/madison/quality-%231-fence-and-deck-co-reviews-9240089.htm#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Angi%20log.png?alt=media&token=aeb86fe2-164f-4557-a399-51c09ea7d6e0',
            red: "Angi",
        },

    ];


    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
            {
                moverReview.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-md bg-gray-100 w-auto">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        <span className="text-[20px] font-semibold"> {item.name} </span>
                                        <Stars score={item.rate} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item.date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item.description}</ReadMore>
                            </div>
                            <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[25px] h-[25px] flex items-center">
                                    <img src={item.ima} alt="agni" className="w-full mx-auto" />
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item.enlace}>{item.red}</a>
                                </div>
                            </div>
                        </article>
                    );
                })
            }
        </section>
    );
}

export default Faq;